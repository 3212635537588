// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `   .content {
    margin-left: 300px;
  }

  .content h2{
    margin-top: 20px;
    margin-left: 10px;
    font-size: 1.87em;
  }
  
  .add, .remove {
    margin-bottom: 20px;
    font-size: 1.5rem;
  }
  
  .add label, .remove label {
    font-weight: bold;
    display: block;
    margin-bottom: 10px;
    margin-top: 30px;
  }
  
  .add, .remove {
    display: flex;
    flex-direction: column;
    align-items: center; /* to center elements horizontally */
  }
  
  .add select, .remove select {
    display: block;
    margin-top: 10px;
    padding: 10px;
    width: 45%;
    font-size: 1.3rem;
  }
  
  .add button, .remove button {
    margin-top: 10px;
    padding: 10px;
    width: 17%;
    font-size: 1.2rem;
    background-color: #007bff;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
  }
  
  .add button:hover, .remove button:hover {
    background-color: #0056b3;
  }
  `, "",{"version":3,"sources":["webpack://./src/components/ManageUsers/ManageUsers.css"],"names":[],"mappings":"GAAG;IACC,kBAAkB;EACpB;;EAEA;IACE,gBAAgB;IAChB,iBAAiB;IACjB,iBAAiB;EACnB;;EAEA;IACE,mBAAmB;IACnB,iBAAiB;EACnB;;EAEA;IACE,iBAAiB;IACjB,cAAc;IACd,mBAAmB;IACnB,gBAAgB;EAClB;;EAEA;IACE,aAAa;IACb,sBAAsB;IACtB,mBAAmB,EAAE,oCAAoC;EAC3D;;EAEA;IACE,cAAc;IACd,gBAAgB;IAChB,aAAa;IACb,UAAU;IACV,iBAAiB;EACnB;;EAEA;IACE,gBAAgB;IAChB,aAAa;IACb,UAAU;IACV,iBAAiB;IACjB,yBAAyB;IACzB,YAAY;IACZ,YAAY;IACZ,kBAAkB;IAClB,eAAe;EACjB;;EAEA;IACE,yBAAyB;EAC3B","sourcesContent":["   .content {\n    margin-left: 300px;\n  }\n\n  .content h2{\n    margin-top: 20px;\n    margin-left: 10px;\n    font-size: 1.87em;\n  }\n  \n  .add, .remove {\n    margin-bottom: 20px;\n    font-size: 1.5rem;\n  }\n  \n  .add label, .remove label {\n    font-weight: bold;\n    display: block;\n    margin-bottom: 10px;\n    margin-top: 30px;\n  }\n  \n  .add, .remove {\n    display: flex;\n    flex-direction: column;\n    align-items: center; /* to center elements horizontally */\n  }\n  \n  .add select, .remove select {\n    display: block;\n    margin-top: 10px;\n    padding: 10px;\n    width: 45%;\n    font-size: 1.3rem;\n  }\n  \n  .add button, .remove button {\n    margin-top: 10px;\n    padding: 10px;\n    width: 17%;\n    font-size: 1.2rem;\n    background-color: #007bff;\n    color: white;\n    border: none;\n    border-radius: 5px;\n    cursor: pointer;\n  }\n  \n  .add button:hover, .remove button:hover {\n    background-color: #0056b3;\n  }\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
