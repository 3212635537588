// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.page2heroContainer {
    border: 2px solid rgb(107, 120, 231);
    display: flex;
    margin: 3rem;
    justify-content: center;
    align-items: center;
    height: calc(80vh - 4rem); /* Adjusting for the navbar height */
  }
  
  .page2lockerForm {
    
    display: flex;
    flex-direction: column;
    width: 85%; /* Adjust as needed */
    /* max-width: 600px; */
  }
  
  .page2lockerForm label {
    display: flex;
    flex-direction: row;
    margin-bottom: 5rem;
   
  }

  .page2lockerForm input {
    padding: 0.5rem ;
    /* margin-top: 0.5rem; */
    border: 2px solid rgb(107, 120, 231);
    border-radius: 0.25rem; 
    width: 55%;
  }
  
  .page2lockerForm button {
    background-color: #007bff;
    color: #fff;
    width: auto;
    border: none;
    padding: 1rem 0.75rem;
    border-radius: 0.25rem;
    cursor: pointer;
    font-weight: bolder;
    /* padding: 0.5rem 0.25rem;  */
    max-width: 200px;

  }

  .page2lockerForm label span {
    margin-right: 5rem; /* Space between label text and input */
    font-size: 1.45rem;
    font-weight: bolder;
    flex: 0.3 1;
  }
  
  .page2lockerForm button:hover {
    background-color: #0056b3;
  }`, "",{"version":3,"sources":["webpack://./src/components/CreateLocker/page2.css"],"names":[],"mappings":"AAAA;IACI,oCAAoC;IACpC,aAAa;IACb,YAAY;IACZ,uBAAuB;IACvB,mBAAmB;IACnB,yBAAyB,EAAE,oCAAoC;EACjE;;EAEA;;IAEE,aAAa;IACb,sBAAsB;IACtB,UAAU,EAAE,qBAAqB;IACjC,sBAAsB;EACxB;;EAEA;IACE,aAAa;IACb,mBAAmB;IACnB,mBAAmB;;EAErB;;EAEA;IACE,gBAAgB;IAChB,wBAAwB;IACxB,oCAAoC;IACpC,sBAAsB;IACtB,UAAU;EACZ;;EAEA;IACE,yBAAyB;IACzB,WAAW;IACX,WAAW;IACX,YAAY;IACZ,qBAAqB;IACrB,sBAAsB;IACtB,eAAe;IACf,mBAAmB;IACnB,8BAA8B;IAC9B,gBAAgB;;EAElB;;EAEA;IACE,kBAAkB,EAAE,uCAAuC;IAC3D,kBAAkB;IAClB,mBAAmB;IACnB,WAAS;EACX;;EAEA;IACE,yBAAyB;EAC3B","sourcesContent":[".page2heroContainer {\n    border: 2px solid rgb(107, 120, 231);\n    display: flex;\n    margin: 3rem;\n    justify-content: center;\n    align-items: center;\n    height: calc(80vh - 4rem); /* Adjusting for the navbar height */\n  }\n  \n  .page2lockerForm {\n    \n    display: flex;\n    flex-direction: column;\n    width: 85%; /* Adjust as needed */\n    /* max-width: 600px; */\n  }\n  \n  .page2lockerForm label {\n    display: flex;\n    flex-direction: row;\n    margin-bottom: 5rem;\n   \n  }\n\n  .page2lockerForm input {\n    padding: 0.5rem ;\n    /* margin-top: 0.5rem; */\n    border: 2px solid rgb(107, 120, 231);\n    border-radius: 0.25rem; \n    width: 55%;\n  }\n  \n  .page2lockerForm button {\n    background-color: #007bff;\n    color: #fff;\n    width: auto;\n    border: none;\n    padding: 1rem 0.75rem;\n    border-radius: 0.25rem;\n    cursor: pointer;\n    font-weight: bolder;\n    /* padding: 0.5rem 0.25rem;  */\n    max-width: 200px;\n\n  }\n\n  .page2lockerForm label span {\n    margin-right: 5rem; /* Space between label text and input */\n    font-size: 1.45rem;\n    font-weight: bolder;\n    flex: 0.3;\n  }\n  \n  .page2lockerForm button:hover {\n    background-color: #0056b3;\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
