// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `body {
    font-family: Arial, sans-serif;
    background-color: #f4f4f4;
  }
  
  .sidebar {
    width: 320px;
    background-color: #73b7ff;
    height: 100vh;
    position: fixed;
    top: 0;
    left: 0;
    padding-top: 240px;
    border-radius: 1em;
  }
  
  .sidebar ul {
    list-style-type: none;
    padding: 0;
    font-weight: bold;
  }
  
  .sidebar ul li {
    padding: 40px;
    text-align: center;
    font-size: large;
  }
  
  .sidebar ul li a {
    color: rgb(0, 0, 0);
    text-decoration: none;
    display: block;
  }
  
  .sidebar ul li.selected {
    background-color: #c5e1ff;
    border-radius: 1em;
    /* background-color: #555; */
  }
  
  .content {
    margin-left: 220px;
    padding: 20px;
  }
  
  h2 {
    color: #333;
  }
  
  .remove, .add{
    margin-top: 40px;
  }`, "",{"version":3,"sources":["webpack://./src/components/Sidebar/Sidebar.css"],"names":[],"mappings":"AAAA;IACI,8BAA8B;IAC9B,yBAAyB;EAC3B;;EAEA;IACE,YAAY;IACZ,yBAAyB;IACzB,aAAa;IACb,eAAe;IACf,MAAM;IACN,OAAO;IACP,kBAAkB;IAClB,kBAAkB;EACpB;;EAEA;IACE,qBAAqB;IACrB,UAAU;IACV,iBAAiB;EACnB;;EAEA;IACE,aAAa;IACb,kBAAkB;IAClB,gBAAgB;EAClB;;EAEA;IACE,mBAAmB;IACnB,qBAAqB;IACrB,cAAc;EAChB;;EAEA;IACE,yBAAyB;IACzB,kBAAkB;IAClB,4BAA4B;EAC9B;;EAEA;IACE,kBAAkB;IAClB,aAAa;EACf;;EAEA;IACE,WAAW;EACb;;EAEA;IACE,gBAAgB;EAClB","sourcesContent":["body {\n    font-family: Arial, sans-serif;\n    background-color: #f4f4f4;\n  }\n  \n  .sidebar {\n    width: 320px;\n    background-color: #73b7ff;\n    height: 100vh;\n    position: fixed;\n    top: 0;\n    left: 0;\n    padding-top: 240px;\n    border-radius: 1em;\n  }\n  \n  .sidebar ul {\n    list-style-type: none;\n    padding: 0;\n    font-weight: bold;\n  }\n  \n  .sidebar ul li {\n    padding: 40px;\n    text-align: center;\n    font-size: large;\n  }\n  \n  .sidebar ul li a {\n    color: rgb(0, 0, 0);\n    text-decoration: none;\n    display: block;\n  }\n  \n  .sidebar ul li.selected {\n    background-color: #c5e1ff;\n    border-radius: 1em;\n    /* background-color: #555; */\n  }\n  \n  .content {\n    margin-left: 220px;\n    padding: 20px;\n  }\n  \n  h2 {\n    color: #333;\n  }\n  \n  .remove, .add{\n    margin-top: 40px;\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
